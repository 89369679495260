import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'
import {BBCode, stripMessageHtml} from './utils/BBCode'
import {FormattedDate} from './utils/FormattedDate'
import {LatestImage} from './utils/LatestImage'
import {Link} from './utils/Link'
import {Spinner} from './utils/Spinner'
import {UserLink} from './utils/UserLink'
import {getConversations} from '../redux/actions'
import {AppState} from '../redux/reducers'
import { followURL } from '../utils'


const ConversationList: React.FC = () => {
  const chat = useSelector((state: AppState) => state.chat)
  const settings = useSelector((state: AppState) => state.user.settings)
  const dispatch = useDispatch()

  if (chat.conversations.length <= 0 && chat.loading)
    return <Spinner />

  if (chat.conversations.length <= 0)
    return <p>Du har inga konversationer än.</p>

  return <>
    <table style={{width: '100%', tableLayout: 'auto'}}>
      <thead>
        <tr>
          <th>Senaste</th>
          <th>Från</th>
        </tr>
      </thead>
      <InfiniteScroll
        element='tbody'
        loadMore={() => {
          dispatch(getConversations(chat.cursor))
        }}
        hasMore={chat.cursor !== null && !chat.loading}
      >
        {chat.conversations.filter((convo) => convo.messages.length > 0).map(convo => {
          const read = convo.messages[0].read || convo.messages[0].user.id != convo.user.id

          const cellClass = read ? '' : 'highlight'

          const url = `conversations/${convo.id}`

          let text = convo.messages[0].text
          if (convo.user.system) {
            // Strip HTML from system messages in this overview, they mess with styling
            text = stripMessageHtml(text)
          }

          return <tr key={convo.id}>
            <td className={cellClass}>
              <div
                style={{
                  maxHeight: '26px',
                  overflow: 'hidden',
                  userSelect: 'none',
                  padding: 0,
                  margin: 0,
                  cursor: 'pointer',
                }}
                onClick={() => followURL(url)}
              >
                <BBCode text={text} options={{
                  html: false,
                  smileys: settings.showSmileys,
                  inLink: true,
                }} />
              </div>
            </td>
            <td className={cellClass}>
              <UserLink user={convo.user} />
              <br />
              <FormattedDate date={convo.messages[0].date} />
              {' '}
              <Link url={url}>
                <LatestImage />
              </Link>
            </td>
          </tr>
        })}
      </InfiniteScroll>
    </table>
    {chat.loading ? <Spinner /> : ''}
  </>
}


export const ConversationListPage: React.FC = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getConversations(null))
  }, [])

  return <>
    <h1>Meddelanden</h1>
    <p><Link url={'message_send.php'}>Skriv nytt...</Link></p>
    <ConversationList />
  </>
}

