
export const findPosX = obj => {
  let curleft = 0
  if (obj.offsetParent)
    while (true) {
      curleft += obj.offsetLeft
      if (!obj.offsetParent)
        break
      obj = obj.offsetParent
    }
  else if (obj.x)
    curleft += obj.x
  return curleft
}

export const findPosY = obj => {
  let curtop = 0
  if (obj.offsetParent)
    while (true) {
      curtop += obj.offsetTop
      if (!obj.offsetParent)
        break
      obj = obj.offsetParent
    }
  else if (obj.y)
    curtop += obj.y
  return curtop;
}

export const refreshPronouns = () => {
  const preview = document.getElementById("pronounPreview") as HTMLSpanElement
  const sexFemale = document.getElementById("sexFemale") as HTMLInputElement
  const sexMale = document.getElementById("sexMale") as HTMLInputElement
  const sexNA = document.getElementById("sexNA") as HTMLInputElement
  const pronounDetails = document.getElementById("pronounDetails") as HTMLTableElement
  const pronounSubjective = document.getElementById("pronounSubjective") as HTMLInputElement
  const pronounObjective = document.getElementById("pronounObjective") as HTMLInputElement
  const pronounPossessive = document.getElementById("pronounPossessive") as HTMLInputElement

  if (sexFemale.checked) {
    preview.innerHTML = "hon/henne/hennes"
    pronounDetails.style.display = "none"
  } else if (sexMale.checked) {
    preview.innerHTML = "han/honom/hans"
    pronounDetails.style.display = "none"
  } else if (sexNA.checked) {
    preview.innerHTML = [pronounSubjective.value, pronounObjective.value, pronounPossessive.value].filter((v) => v).join("/")
    pronounDetails.style.display = "block"
  }
}


export const refreshCalendarCoords = () => {
  window.addEventListener("resize", refreshCalendarCoords)

  const coordsJson = document.getElementById("calender_coords_json") as HTMLInputElement
  const calendarImage = document.getElementById("calendarImage") as HTMLImageElement
  const calendarMap = document.getElementById("calendarmap") as HTMLMapElement

  if (!coordsJson || !calendarImage || !calendarMap) {
    window.removeEventListener("resize", refreshCalendarCoords)
    return
  }

  const fullWidth = calendarImage.naturalWidth
  const actualWidth = calendarImage.clientWidth
  const scale = actualWidth / fullWidth

  const coords = JSON.parse(coordsJson.value)
  const mobileCoords = []

  for (const coord of coords) {
    const values = coord.split(", ")
    const mobileValues = values.map((c) => Math.round(parseInt(c) * scale))
    mobileCoords.push(mobileValues.join(","))
  }

  for (let i = 0; i < calendarMap.children.length; ++i) {
    (calendarMap.children.item(i) as HTMLAreaElement).coords = mobileCoords[i]
  }
}
